import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { SessionService } from './session.service';
import { Observable, of, throwError, zip, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { LogLevel, LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class FormDataPersistenceService {
  constructor(
    private configService: ConfigService,
    private sessionService: SessionService,
    private http: HttpClient
  ) {}

  additionalColumns: any;
  documentSaves: { [x: string]: Observable<any>[] } = {};

  static escapeRegExp(s: string) {
    return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  public init() {
    // This has to return a promise because it's used with APP_INITIALIZER

    return Promise.resolve().then(() => {
      this.additionalColumns = {
        '1_0_Contact_Info': {
          last_name: '{{payload.data[0][1].data.filer_lastName}}',
          first_name: '{{payload.data[0][1].data.filer_firstName}}',
          middle_initial: '{{payload.data[0][1].data.filer_middleInitial}}',
        },
        '1_0_Contact_Info_By_Reviewer': {
          // nothing
        },
        '1_2_Issue_or_Feedback': {
          // nothing
        },
        '1_3_Comment': {
          // nothing
        },
        filing: {
          item: '{{payload.data[0][1].data.item}}',
          year: '{{payload.data[0][1].data.year}}',
          type: '{{payload.data[0][1].data.type}}',
          group_surrogate_key: '{{payload.data[0][1].data.groupSurrogateKey}}',
          position: '{{payload.data[0][1].data.position}}',
          due: '{{payload.data[0][1].data.due}}',
          status: '{{payload.data[0][1].data.workflow.current.status}}',
          user_id: '{{payload.data[0][1].data.userId}}',
          filer_certified:
            '{{payload.data[0][1].data.filerCertified != null ? payload.data[0][1].data.filerCertified : false}}',
          certify_time: '{{payload.data[0][1].data.certifyTime}}',
          assigned_to: '{{payload.data[0][1].data.assignedTo}}',
          assigned_to_role_surrogate_key:
            '{{payload.data[0][1].data.assignedToRoleSurrogateKey}}',
          filing_type_surrogate_key: '{{payload.data[0][1].data.filingType}}',
          filerCategory: '{{payload.data[0][1].data.filerCategory}}',
          workflow_step: '{{payload.data[0][1].data.workflowStep}}',
          retained:
            '{{payload.data[0][1].data.retained != null ? payload.data[0][1].data.retained : false}}',
          purged:
            '{{payload.data[0][1].data.purged != null ? payload.data[0][1].data.purged : false}}',
          modified_by: this.sessionService.getMaxUsername(),
          signature_role: '{{null}}',
          amendment: '{{payload.data[0][1].data.amendment}}',
          agency_reviewer:
            '{{payload.data[0][1].data.agencyReviewer == null || payload.data[0][1].data.agencyReviewer.trim().equals("") ? null : payload.data[0][1].data.agencyReviewer}}',
          oge_reviewer:
            '{{payload.data[0][1].data.ogeReviewer == null || payload.data[0][1].data.ogeReviewer.trim().equals("") ? null : payload.data[0][1].data.ogeReviewer}}',
          nominee_allow_agency_routers:
            '{{payload.data[0][1].data.nomineeContext != null && payload.data[0][1].data.nomineeContext.allowAgencyRouters != null ? payload.data[0][1].data.nomineeContext.allowAgencyRouters : false}}',
          agency_certification_close_date:
            '{{payload.data[0][1].data.agencyCertificationCloseDate}}',
          oge_certification_close_date:
            '{{payload.data[0][1].data.ogeCertificationCloseDate}}',
          extension:
            '{{payload.data[0][1].data.extension == null || payload.data[0][1].data.extension.trim().equals("") ? null : payload.data[0][1].data.extension}}',
          late_fee_waiver:
            '{{payload.data[0][1].data.lateFeeWaiver == null || payload.data[0][1].data.lateFeeWaiver.trim().equals("") ? null : payload.data[0][1].data.lateFeeWaiver}}',
          late_fee_paid:
            '{{payload.data[0][1].data.lateFeePaid != null ? payload.data[0][1].data.lateFeePaid : false}}',
          filer_status:
            '{{payload.data[0][1].data.filerStatus == null || payload.data[0][1].data.filerStatus.trim().equals("") ? null : payload.data[0][1].data.filerStatus}}',
          daeo: '{{payload.data[0][1].data.daeo != null ? payload.data[0][1].data.daeo : false}}',
        },
        filing_extension: {
          request_date: '{{payload.data[0][1].data.requestDate != null ? payload.data[0][1].data.requestDate : 0}}',
          review_date: '{{payload.data[0][1].data.reviewDate != null ? payload.data[0][1].data.reviewDate : 0}}',
          user_id: '{{payload.data[0][1].data.sessionUserId}}',
          status: '{{payload.data[0][1].data.status}}',
          latest: '{{payload.data[0][1].data.latest != null ? payload.data[0][1].data.latest : false}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
          extension_type: '{{payload.data[0][1].data.extensionType}}',
        },
        '2_1_My_Filing': {
          last_name: '{{payload.data[0][1].data.report_lastName}}',
          first_name: '{{payload.data[0][1].data.report_firstName}}',
          middle_initial: '{{payload.data[0][1].data.report_middleInitial}}',
          position: '{{payload.data[0][1].data.report_positionTitle}}',
          filer_category: '{{payload.data[0][1].data.report_filerCategory}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
          modified_by: this.sessionService.getMaxUsername(),
        },
        '278_0_T_Cover_Page': {
          last_name: '{{payload.data[0][1].data.report_last_name}}',
          first_name: '{{payload.data[0][1].data.report_first_name}}',
          middle_initial: '{{payload.data[0][1].data.report_middle_initial}}',
          position: '{{payload.data[0][1].data.report_positionTitle}}',
          agency: '{{payload.data[0][1].data.report_agencyName}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
          modified_by: this.sessionService.getMaxUsername(),
        },
        '4_1_Corporate_Positions': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_1_Corp_Salary_Bonus': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_2_Corp_Director_Fees': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_5_Corp_Severance': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_6_Corp_Other_Income': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_7_Corp_Stock': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_8_Corp_ESOP': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_9_Corp_ESPP': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_10_Corp_Stock_Options': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_12_Corp_Restricted_Stock': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_13_Corp_Restricted_Stock_Un': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_14_Corp_Phantom_Stock': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_15_Corp_Stock_Apprec_Rights': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_161_Corp_Deferred_Comp': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_162_Corp_Deferred_Comp': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_163_Corp_Deferred_Comp': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_164_Corp_Deferred_Comp': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_1_165_Corp_Deferred_Comp': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_AttorneyE': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_1_AttorneyE_Partnship_Share': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_2_AttorneyE_LLC_Distributio': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_3_AttorneyE_Salary_and_Bonu': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_4_AttorneyE_Severance': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_5_AttorneyE_Other_Income': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_6_AttorneyE_Capital_Account': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_7_AttorneyE_Firm_Stock': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_8_AttorneyE_Contingency_Fee': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_2_9_AttorneyE_Other_Asset': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_3_AttorneyE_Solo': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_4_Dean_Professor': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_5_1_Employee_Salary_Bonus': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_5_2_Employee_Severance': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_5_3_Other_Income': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_5_Employee': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_6_Indep_Contractor_Consultant': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_9_Trustee': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_10_1_Other_Salary_Bonus': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_10_2_Other_Severance': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_10_3_Other_Other_Income': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_10_4_Other_Asset': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        '4_10_Other_Position': {
          position_id: '{{payload.data[0][1].data.positionId}}',
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
        filing_documents: {
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
          user_id: this.sessionService.getMaxUsername(),
        },
        agency_documents: {
          agency_surrogate_key: '{{payload.data[0][1].data.agencyId}}',
          category: '{{payload.data[0][1].data.category}}',
          sort_order: '{{payload.data[0][1].data.sortOrder}}',
        },
        users_meta_info: {
          filer_id: '{{payload.data[0][1].data.filerId}}',
          type: '{{payload.data[0][1].data.type}}',
          special_agency_id: '{{payload.data[0][1].data.specialAgencyId}}',
          max_login_email: '{{payload.data[0][1].data.maxLoginEmail}}',
          canonical_id: '{{payload.data[0][1].data.canonicalId}}',
          status: '{{payload.data[0][1].data.status}}',
          user_id: this.sessionService.getMaxUsername(),
        },
        user_login: {
          // nothing
        },
        user_import_job: {
          type: '{{payload.data[0][1].data.type}}',
          uploader: '{{payload.data[0][1].data.uploader}}',
          group_surrogate_key: '{{payload.data[0][1].data.groupSurrogateKey}}',
        },
        performance_test_results: {
          // nothing
        },
        default: {
          filing_surrogate_key: '{{payload.data[0][1].data.filingId}}',
        },
      };
    });
  }

  private getAdditionalColumns(formId: string) {
    let toAdd = this.additionalColumns[formId];
    if (!toAdd) {
      toAdd = this.additionalColumns.default;
    }

    return toAdd;
  }

  // Converts a formId to it's associated database table name
  private getFormTableName(formId: string) {
    formId = formId.toLowerCase();

    const nonFormTables = [
      'filing',
      'filing_documents',
      'filing_extension',
      'agency_documents',
      'users_meta_info',
      'user_login',
      'user_import_job',
      'performance_test_results',
    ];

    if (nonFormTables.includes(formId)) {
      return formId;
    }

    return 'form_' + formId;
  }

  // TODO: needs old sanitizeStringSpecialCharacters logic
  public get(formId: string, documentId: string): Observable<any> {
    // This function is called without a documentId, return "not found" status.
    if (!documentId) {
      return of({ status: 50 });
    }

    formId = this.getFormTableName(formId);

    const serviceEndpoint = `${ConfigService.MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_RETRIEVE}${formId}.surrogate_key/placeholder/${documentId}.json`;
    //console.log('FormDataPersistenceFactory.get endpoint -> ' + serviceEndpoint);

    return this.http.get(serviceEndpoint).pipe(
      map((data: any) => {
        if (data.results && data.results.data) {
          data.results.data = JSON.parse(data.results.data);
        }

        return data;
      }),
      catchError((error) => {
        return of({ status: 50, statusMessage: 'Not Found' });
      })
    );
  }

  public getOrThrow(formId: string, documentId: string): Observable<any> {
    // This function is called without a documentId, return "not found" status.
    if (!documentId) {
      throw new Error('Missing documentId');
    }

    formId = this.getFormTableName(formId);

    const serviceEndpoint = `${ConfigService.MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_RETRIEVE}${formId}.surrogate_key/placeholder/${documentId}.json`;
    //console.log('FormDataPersistenceFactory.get endpoint -> ' + serviceEndpoint);

    return this.http.get(serviceEndpoint).pipe(
      map((data: any) => {
        if (data.status != 0) {
          throw new Error(
            data.statusMessage && data.statusMessage.statusMessage
              ? data.statusMessage.statusMessage
              : data.statusMessage
          );
        }

        if (data.results && data.results.data) {
          data.results.data = JSON.parse(data.results.data);
        }

        return data;
      })
    );
  }

  public delete(formId: string, documentId: string): Observable<any> {
    formId = this.getFormTableName(formId);

    const endpoint = `${ConfigService.MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_DELETE}${formId}.surrogate_key/placeholder/${documentId}.json`;
    const headers = {
      Authorization: this.sessionService.getCas(),
      'x-user-id-column': 'user_id',
      'x-user-id-value': this.sessionService.getMaxUsername(),
    };

    return this.http.delete<any>(endpoint, { headers }).pipe(
      map((result) => {
        if (result && result.status !== 0) {
          const message =
            result.statusMessage && result.statusMessage.statusMessage
              ? result.statusMessage.statusMessage
              : result.statusMessage;
          LogService.log(LogLevel.ERROR, message);
          throw new Error(message);
        } else {
          return result;
        }
      })
    );
  }

  public save(
    formId: string,
    documentId: string,
    data: any,
    addFilingIds: boolean = false
  ): Observable<any> {
    const tableId = this.getFormTableName(formId);

    const createEndpoint =
      ConfigService.MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_CREATE;
    const getEndpoint = `${ConfigService.MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_RETRIEVE}${tableId}.surrogate_key/placeholder/${documentId}.json`;
    const updateEndpoint = `${ConfigService.MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_UPDATE}placeholder/${documentId}.json`;

    // TODO build out addFilingIds functionality
    const x = addFilingIds;

    // Prepare the row that is to be stored in the database
    let tableRow = {
      data,
      surrogate_key: documentId,
    };

    // Add form-specific columns to the row to be stored to the database
    const additionalColumns = this.getAdditionalColumns(formId);
    tableRow = { ...tableRow, ...additionalColumns };
    if (data._transientColumns) {
      tableRow = { ...tableRow, ...data._transientColumns };
      delete data._transientColumns;
    }

    data.session_user_id = this.sessionService.getMaxUsername();
    data.server_timestamp = '{{java.lang.System.currentTimeMillis()}}';

    // Typically SecureProxy(now) or Persistence(future) would handle these jexl replacements.
    // But when bypassing authorization, the request doesn't go through SecureProxy, so
    // do the replacement here for local development usage
    if (this.sessionService.isAuthBypassed()) {
      data.server_timestamp = new Date().getTime();
      let str = JSON.stringify(tableRow);
      str = str.replace(
        new RegExp(
          FormDataPersistenceService.escapeRegExp(
            '"{{payload.data[0][1].data.server_timestamp}}"'
          ),
          'g'
        ),
        data.server_timestamp
      );
      tableRow = JSON.parse(str);
    }

    const saves = this.getDocumentSaves(formId, documentId);
    const blocker = saves.length ? forkJoin(saves) : of([]);

    return blocker.pipe(
      mergeMap(() => {
        return this.http.get(getEndpoint).pipe(
          mergeMap((status1: any) => {
            if (status1.status === 0) {
              // Create the structure required by max-paas for performing an update
              const updateObj = {
                data: [
                  [
                    tableId,
                    tableRow,
                    {
                      surrogate_key: documentId,
                      /*primary_key: "id"*/ // bug, venkat to fix
                    },
                  ],
                ],
              };

              return this.http.put(updateEndpoint, updateObj).pipe(
                map((status2: any) => {
                  if (status2.status !== 0) {
                    throwError(status2.statusMessage);
                  } else {
                    if (formId === 'filing') {
                      // Notify parent window
                      this.notifyParentWindowOfFilingChange(formId);
                    }
                  }

                  return status2;
                })
              );
            } else if (status1.status === 50) {
              // Create the structure required by max-paas for performing an insert
              const insertObj = {
                data: [[tableId, tableRow]],
              };

              return this.http.post(createEndpoint, insertObj).pipe(
                map((status: any) => {
                  if (status.status !== 0) {
                    throw new Error(
                      status.statusMessage && status.statusMessage.statusMessage
                        ? status.statusMessage.statusMessage
                        : status.statusMessage
                    );
                  } else {
                    if (formId === 'filing') {
                      // Notify parent window
                      this.notifyParentWindowOfFilingChange(formId);
                    }

                    return status;
                  }
                })
              );
            } else {
              return throwError(`Status ${status1.status} not handled`);
            }
          })
        );
      })
    );
  }

  private getDocumentSaves(
    formId: string,
    documentId: string
  ): Observable<any>[] {
    const key = formId + '-' + documentId;
    this.documentSaves[key] = this.documentSaves[key] ?? [];

    // Send a copy snapshot in time of the promises array back to the $q.all() in the calling function.
    const promises: Observable<any>[] = this.documentSaves[key] || [];

    // Add the promise for the calling function to the promises stack after snapshot generated.
    // this.documentSaves[key].push(newPromise);

    return promises;
  }

  notifyParentWindowOfFilingChange(filingId: string | undefined = undefined) {
    if (parent) {
      parent.postMessage(
        {
          model: 'Filing',
          action: 'Update',
          id: filingId,
        },
        location.origin
      );
    }
  }
}
