import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef, EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { ReportUtilitiesService } from '../../services/report-utilities.service';
import { FilingService } from '../../services/filing.service';
import { Filing } from '../../models/filing.model';
import { mergeMap, tap, catchError, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, of, Observable } from 'rxjs';
import { MenuItem, MenuItems } from '../../models/menu-items.model';
import { IntegrityPersistenceService } from '../../services/integrity-persistence.service';
import { SessionCheckService } from '../../services/session-check.service';
import { FormDataPersistenceService } from '../../services/form-data-persistence.service';
import { HttpClient } from '@angular/common/http';
import { BaseFilingMenuItemsComponent } from '../base-filing-menu-items.component';
import { QueryService } from '../../services/query.service';
import { SubWindowsManagerService } from '../../services/sub-windows-manager.service';
import { MenuItemsService } from '../menu-items.service';

@Component({
  host: { style: 'display: none' },
  selector: 'integrity-filing-menu-items',
  templateUrl: './filing-menu-items.component.html',
  styleUrls: ['./filing-menu-items.component.scss'],
  providers: [MenuItemsService],
})
export class FilingMenuItemsComponent
  extends BaseFilingMenuItemsComponent
  implements OnInit
{
  // This template/content stuff is used to move the content outside of the
  // <integrity-filing-menu-items> element in the html. This is needed
  // because the css is set up to expect the menu ul/li/a elements directly
  // inside each other.
  // Taken from: https://github.com/angular/angular/issues/18877
  @ViewChild('content', { static: true }) content: TemplateRef<{}>;

  filing: Filing;
  filerName: string;
  hasSpouse: boolean = true; // default to yes to show all links
  passed5Outro: boolean = false;
  passed10Outro: boolean = false;
  url$: BehaviorSubject<string | null>;
  menuItems: MenuItems;

  constructor(
    viewContainer: ViewContainerRef,
    filingService: FilingService,
    reportUtilitiesService: ReportUtilitiesService,
    router: Router,
    integrityPersistenceService: IntegrityPersistenceService,
    sessionCheckService: SessionCheckService,
    formDataPersistenceService: FormDataPersistenceService,
    http: HttpClient,
    queryService: QueryService,
    subWindows: SubWindowsManagerService,
    public menuItemsService: MenuItemsService,
  ) {
    super(
      viewContainer,
      filingService,
      reportUtilitiesService,
      router,
      integrityPersistenceService,
      sessionCheckService,
      formDataPersistenceService,
      http,
      queryService,
      subWindows
    );
    this.checkHighwater = true;
  }

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.content);

    this.init('assets/json/menu-filer-filing.json');
    this.menuItemsService.init();
  }

  pullAdditionalData(): { [key: string]: Observable<any> } {
    const observables: { [key: string]: Observable<any> } = {};

    if (this.filing.type == '278') {
      observables['positionOutro'] = this.formDataPersistenceService
        .get('5_100_Outro', this.filing.filingId)
        .pipe(
          tap((results) => {
            if (
              results.results &&
              results.results.data.confirmed &&
              (results.results.data.confirmed as string[]).includes(
                'do_you_wish_to_continue'
              )
            ) {
              this.passed5Outro = true;
            }
          })
        );
      observables['spouseOutro'] = this.formDataPersistenceService
        .get('10_100_Outro', this.filing.filingId)
        .pipe(
          tap((results) => {
            if (
              results.results &&
              results.results.data.confirmed &&
              (results.results.data.confirmed as string[]).includes(
                'do_you_wish_to_continue'
              )
            ) {
              this.passed10Outro = true;
            }
          })
        );
    }

    return observables;
  }

  customizeMenuItems() {
    this.menuItems.items.forEach((item) => {
      item.items.forEach((subItem) => {
        if (
          this.passed5Outro &&
          (subItem.title == 'Position-Related Interests' ||
            subItem.title == 'Retirement Plans')
        ) {
          subItem.isEnabled = false;
        }

        if (
          this.passed10Outro &&
          (subItem.title == "Spouse's Employment" ||
            subItem.title == "Spouse's Retirement Plans")
        ) {
          subItem.isEnabled = false;
        }
      });
    });
  }

  pullData(filingId: string) {
    return this.reportUtilitiesService.getFiling(filingId).pipe(
      mergeMap((filingRes) => {
        this.filing = filingRes;
        this.menuItems.setFiling(this.filing);

        const observables = {
          filerName: this.reportUtilitiesService
            .getFilerFullName(this.filing)
            .pipe(
              tap((filerName) => {
                this.filerName = filerName;
              })
            ),
        };

        if (this.filing.type == '278') {
          observables['spouse'] = this.reportUtilitiesService
            .getIndicativeData(this.filing)
            .pipe(
              // EFEDS-6560: getIndicativeData() error message written
              // to console.log by the function itself.
              catchError((error) => of(null)),
              tap((indicativeData) => {
                if (
                  !!indicativeData &&
                  indicativeData.hasOwnProperty('report_hasSpouse') &&
                  indicativeData.report_hasSpouse == 'no_spouse'
                ) {
                  this.hasSpouse = false;
                }
              })
            );
          observables['positionOutro'] = this.formDataPersistenceService
            .get('5_100_Outro', filingId)
            .pipe(
              tap((results) => {
                if (
                  results.results &&
                  results.results.data.confirmed &&
                  (results.results.data.confirmed as string[]).includes(
                    'do_you_wish_to_continue'
                  )
                ) {
                  this.passed5Outro = true;
                }
              })
            );
          observables['spouseOutro'] = this.formDataPersistenceService
            .get('10_100_Outro', filingId)
            .pipe(
              tap((results) => {
                if (
                  results.results &&
                  results.results.data.confirmed &&
                  (results.results.data.confirmed as string[]).includes(
                    'do_you_wish_to_continue'
                  )
                ) {
                  this.passed10Outro = true;
                }
              })
            );
        }

        return forkJoin(observables);
      })
    );
  }

  menuItemActive(url: string) {
    if (this.newHighwaterIndex) {
      this.updateHighwaterIndex(this.filing.navHighwaterIndex);
    }
  }

  updateHighwaterIndex(index: number) {
    this.reportUtilitiesService
      .saveReportFragment(this.filing.filingId, this.filing, ['navHighwaterIndex'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {});
  }

  callAction(item: MenuItem, action: string) {
    switch (action) {
      case 'publicFinancialDisclosureGuide':
        window.open('https://www.oge.gov/Web/278eGuide.nsf', '_blank');
        break;

      default:
        console.log('--> Unhandled action ' + action);
    }
  }

  closeWindow(): void {
    this.router.navigate(['filer/close-window']);
  }
}
