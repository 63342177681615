import {
  Exclude,
  instanceToPlain,
  plainToInstance,
  Transform,
  TransformationType
} from 'class-transformer';
import { Util } from '../util';

function TransformYesNo() {
  return Transform(({ value, type }) => {
    switch (type) {
      case TransformationType.PLAIN_TO_CLASS:
        return value === true || value === 'Yes';
      case TransformationType.CLASS_TO_PLAIN:
        return value === true || value === 'Yes' ? 'Yes' : 'No';
      default:
        return value;
    }
  });
}


export class BaseGroupConfigs {
  // EFEDS-6263 bulk action delete notification
  @TransformYesNo()
  bulkActionRecordsManagementNotification = false;
  bulkActionRecordsManagementRecipients = '';
  bulkActionRecordsManagementText =
    'A bulk records management request has been initiated by [[bulkActionUsername]] ([[bulkActionUserEmail]]) for one or more reports in your agency. The request will be processed if not canceled by [[bulkActionExecutionDateTime]]. Please visit the Records Management screen to find the affected reports by using the Customize Display feature to reveal the Pending Purge and Pending Deletion columns, then use the sort controls to show those rows with Yes in either column.';
  bulkActionRecordsManagementCancelationText =
    'A previously scheduled bulk management request for one or more reports in your agency has been canceled by [[bulkActionCancelUsername]] ([[bulkActionCancelUserEmail]]). The original request was initiated by [[bulkActionUsername]] ([[bulkActionUserEmail]]) at [[bulkActionRequestDateTime]].';

  public static createFromJson(json: any): BaseGroupConfigs {
    return plainToInstance(BaseGroupConfigs, json);
  }

  public toJSON(): any {
    return instanceToPlain(this);
  }
}

export class AgencyConfigs extends BaseGroupConfigs {
  static twoFactorAuthenticationNotice = '\n\nNOTE: When you reset your password, you will need your PIV/CAC or an Authenticator app installed on your phone before beginning the password reset process. MAX.gov will prompt you to set up 2-factor login.\n\nWhat is an authenticator app? It is an application on your mobile phone that produces temporary six-digit verification codes that provide a "second factor" for authentication and gives us more confidence in your identity. Here are some examples:\n\n- Google Authenticator (Google Play Store) (iOS Store)\n\n- Microsoft Authenticator (Google Play Store) (iOS Store)\n\n- Authy (Google Play Store) (iOS Store)\n\nNOTE: If you do not have an authenticator app on your mobile device, you must install one first before starting the registration process.';

  @TransformYesNo()
  allowExtensionRequest = false;
  // OGE-8236
  allowExtensionRequestStartDate: string;
  allowExtensionRequestStopDate: string;
  isDoDagency: false;
  @TransformYesNo()
  requireJobDescription = false;
  @TransformYesNo()
  requireDoDnotice = false;
  @TransformYesNo()
  earlyTermination = false;
  @TransformYesNo()
  cascadeAllRoles = true;
  @TransformYesNo()
  displayIntermediateReviewers = false;
  @TransformYesNo()
  helpResource = false;
  helpResourceText?: string;
  helpResourceLink?: string;
  showEmailHelpLink = false;
  customSenderEmail = false;
  customSenderEmailAddress: string;
  useCc = false;
  ccEmailAddress?: (string | null)[];
  @TransformYesNo()
  userProvisioning = true;
  userProvisioningText =
    'Dear [[name]],\n\nYou have been granted access to use Integrity, the system created by the U.S. Office of Government Ethics for electronically filing and reviewing public financial disclosure reports.\n\nLogging into Integrity requires an account with MAX.gov.\n\n- If you are currently a MAX.gov user, you can access Integrity by going to integrity.gov.  Enter your existing MAX ID and password.\n\n- If you are not a current MAX.gov user, please use the following link to set up your password:\n\n  [[passwordResetUrl]]\n\nAfter setting up your password, you can access Integrity by going to integrity.gov.' + AgencyConfigs.twoFactorAuthenticationNotice;
  @TransformYesNo()
  userProvisioningCloaked = true;
  userProvisioningCloakedText =
    'Dear [[name]],\n\nYou have been granted access as a [[accessType]] to [[filerName]] within Integrity, the system created by the U.S. Office of Government Ethics for electronically filing and reviewing public financial disclosure reports.\n\nYour access as a [[accessType]] will occur through a special account that will be different from any other login rights to Integrity that you might have.  This special account limits the number of individuals who can see that you are a user within Integrity.\n\n- Account login ID: [[cloakedId]]\n\n- Account password:  Please use the following link to set up your password to this account.\n\n  [[passwordResetUrl]]\n\nAfter setting up your password, you can access Integrity by going to integrity.gov.' + AgencyConfigs.twoFactorAuthenticationNotice;
  @TransformYesNo()
  annualProfileUpdate = true;
  annualProfileUpdateText =
    'Please review your user profile to ensure that the information is still accurate. Out-of-date information may affect the processing of your report. You may access your profile by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  reportAssignment = true;
  reportAssignmentText =
    'Dear [[filer]],\n\n[[name]] has assigned you a report in Integrity at www.integrity.gov.';
  @TransformYesNo()
  assignmentReminder = true;
  assignmentReminderFrequency = 'every_7_days';
  assignmentReminderText =
    'This notice is a reminder that your [[year]] [[item]] report is due on [[date]]. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  pastDueReminder = false;
  pastDueReminderFrequency = 'every_7_days';
  pastDueReminderText =
    'This notice is a reminder that your [[year]] [[item]] report was due on [[date]]. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  amendReport = true;
  amendReportText =
    'Dear [[filer]],\n\n[[name]] has requested that you amend your [[year]] [[item]] filed [[date]]. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  amendReportReminder = true;
  amendReportReminderText =
    'Dear [[filer]],\n\n[[name]] has requested that you amend your [[year]] [[item]] filed [[date]]. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  certification = false;
  certificationText =
    'Your [[year]] [[item]] report has been certified by [[agency]]. You may view the certified copy of your report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  closes = false;
  closesText =
    'Your [[year]] [[item]] report has been closed without certification by [[agency]], www.integrity.gov. If you have any questions concerning this notice, please contact [to be entered by Agency].';
  @TransformYesNo()
  pendingAction = true;
  pendingActionText =
    'A [[year]] [[item]] report for [[name]] is pending your action as [[role]]. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  pendingActionReminder = true;
  pendingActionReminderFrequency = 'every_7_days';
  pendingActionReminderText =
    'A [[year]] [[item]] report for [[name]] is pending your action as [[role]]. The report has been pending for [[days]] days. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  specialUserProvisioning = true;
  specialUserProvisioningText =
    'Dear [[name]],\n\nYou have been granted access to use Integrity, the system created by the U.S. Office of Government Ethics for electronically filing and reviewing public financial disclosure reports.\n\nLogging into Integrity requires an account with MAX.gov.\n\n- If you are currently a MAX.gov user, you can access Integrity by going to integrity.gov.  Enter your existing MAX ID and password.\n\n- If you are not a current MAX.gov user, please use the following link to set up your password:\n\n  [[passwordResetUrl]]\n\nAfter setting up your password, you can access Integrity by going to integrity.gov.' + AgencyConfigs.twoFactorAuthenticationNotice;
  @TransformYesNo()
  specialUserProvisioningCloaked = true;
  specialUserProvisioningCloakedText =
    'Dear [[name]],\n\nYou have been registered as a Designee to [[filerName]] within Integrity, the system created by the U.S. Office of Government Ethics for electronically filing and reviewing public financial disclosure reports. Your access as a Designee will occur through a special account that will be different from any other login rights to Integrity that you might have. This special account limits the number of individuals who can see that you are a user within Integrity.\n\nAccount login ID: [[cloakedId]]\n\nAccount password: Please use the following link to set up your password to this account.\n\n[[passwordResetUrl]]\n\nAfter setting up your password, you can access Integrity by going to https://integrity.gov.' + AgencyConfigs.twoFactorAuthenticationNotice;
  @TransformYesNo()
  nomineeDraftReadyPPO = false;
  nomineeDraftReadyPPOText =
    '[[name]] submitted a report for review in Integrity, www.integrity.gov.';
  @TransformYesNo()
  nomineeDraftReadyWHC = false;
  nomineeDraftReadyWHCText =
    '[[name]] submitted a report for review in Integrity, www.integrity.gov.';
  @TransformYesNo()
  reviewerPendingAction = false;
  reviewerPendingActionText =
    'A [[year]] [[item]] report for [[name]] is pending your action as supervisor. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  reviewerPendingActionReminder = false;
  reviewerPendingActionReminderText =
    'A [[year]] [[item]] report for [[name]] is pending your action as [[role]]. The report has been pending for [[days]] days. You may access the report by logging into Integrity at www.integrity.gov.';
  ogePreclearanceText =
    'A nominee report for [[name]], [[agency]] has been precleared by OGE in Integrity at www.integrity.gov.';
  ogeCertificationText =
    'The [[year]] [[item]] report for [[name]] has been certified by OGE in Integrity at www.integrity.gov.';
  ogeNomineeCertificationText =
    'The [[year]] [[item]] report for [[name]] has been certified by OGE in Integrity at www.integrity.gov.';
  ogeClosesText =
    'The [[year]] [[item]] report for [[name]] has been closed without certification by OGE in Integrity at www.integrity.gov. If you have any questions concerning this notice, please contact [to be entered by OGE].';
  ogePreclearanceRoleRecipients: string[] = [];
  ogeCertificationRoleRecipients: string[] = [];
  ogeNomineeCertificationRoleRecipients: string[] = [];
  ogeClosesRoleRecipients: string[] = [];
  @TransformYesNo()
  filerExtensionRequest = false;
  filerExtensionRequestText =
    '[[name]] has requested an extension on a [[year]] [[item]] report for your approval. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  filerExtensionRequestWithdrawn = false;
  filerExtensionRequestWithdrawnText = '[[name]] has withdrawn their extension request for their [[year]] [[item]] report. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  filerExtensionRequestReminder = false;
  filerExtensionRequestReminderFrequency = 'every_7_days';
  filerExtensionRequestReminderText =
    '[[name]] has requested an extension on a [[year]] [[item]] report for your approval. You may access the report by logging into Integrity at www.integrity.gov.';
  @TransformYesNo()
  reviewerExtensionRequestDecision = true;
  reviewerExtensionRequestDecisionText =
    'Your request to extend your [[year]] [[item]] report by [[extension]] has been [[decision]] by [[reviewerName]]. [[comment]]. The new report due date is [[due]]. You may access the report by logging into Integrity at www.integrity.gov.';
  ogeLoginSecurityLink = '';
  minAuthLevelForNonFilers = '1';
  extensionReviewerRoles: { [key: string]: Array<string> };
  
  public static createFromJson(json: any): AgencyConfigs {
    return plainToInstance(AgencyConfigs, json);
  }
}

export class OgeOversightConfigs extends AgencyConfigs {
  @TransformYesNo()
  hideLoginOn = false;
  @TransformYesNo()
  maintenanceNoticeOn = false;
  maintenanceNoticeHtml = '';
  @TransformYesNo()
  registerHtmlOn = true;
  registerHtml =
    'Not Registered in <em>Integrity?</em> Contact your agency if you are not registered <em>Integrity.</em> <a href="#">View agency contacts</a>';
  carouselHtml1 =
    '<p><em>Integrity</em> is the U.S. Office of Government Ethics system for electronically filing and reviewing Public Financial Disclosures (OGE Forms 278 and 278-T).</p><p class="hide"><a href="#">Learn more</a></p>';
  carouselHtml2 =
    '<h2>Report filing Deadlines</h2><ul><li>New entrant: 30 days after start date</li><li>Annual: May 15th</li><li>Termination: 30 days after separation</li><li>Periodic Transaction: the earlier of 45 days after the transaction or 30 days after receiving notice of the transaction</li></ul><p class="hide"><a href="#">Learn more</a></p>';
  carouselHtml3 =
    '<p>Contact your agency ethics official to request a filing extension or for more information about using Integrity.</p><p class="hide"><a href="#">Learn more</a></p>';
  @TransformYesNo()
  homeLinkOn = true;
  homeLink = '#';
  @TransformYesNo()
  learnLinkOn = true;
  learnLink = '#';
  @TransformYesNo()
  securityLinkOn = true;
  securityLink = '#';
  @TransformYesNo()
  privacyLinkOn = true;
  privacyLink = '#';
  @TransformYesNo()
  accessibilityLinkOn = true;
  accessibilityLink = '#';
  @TransformYesNo()
  helpLinkOn = true;
  helpLink = '#';

  public static createFromJson(json: any): OgeOversightConfigs {
    return plainToInstance(OgeOversightConfigs, json);
  }

  public toJSON(): any {
    return instanceToPlain(this);
  }
}

export class GroupConfigs extends BaseGroupConfigs {
  public static createFromJson(json: any): GroupConfigs {
    return plainToInstance(GroupConfigs, json);
  }

  public toJSON(): any {
    return instanceToPlain(this);
  }
}
