import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public static readonly DEBUG = !environment.production;
  public static readonly INTEGRITY_DEBUG = !environment.integrityProduction;
  public static readonly DOMAIN = environment.url;
  public static readonly HTTPS = environment.https;
  public static readonly SHOW_RULES_VERSION = environment.showRulesVersion;

  // The domain used when constructing email addresses for cloaked users. Strip out 'www.' if it is contained in the domain name.
  public static readonly INTEGRITY_EMAIL_DOMAIN = environment.url
    .replace(/(^.*\/\/)([^:]*)(:\d*)?(\/.*$)/, '$2')
    .replace('www.', '');

  public static readonly MAX_PAAS_FORM_TEMPLATE_PERSISTENCE_URL: string = `${environment.servicePath.FORMS_TEMPLATE_PERSISTENCE_SERVICE_PATH}s/form/template/excel/filestorage/forms.templates.excel/forms/`;

  public static readonly MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_CREATE: string =
    environment.servicePath.FORMS_DATA_PERSISTENCE_SERVICE_PATH +
    's/form/data/rdbms/efeds/formdata/CREATE.json';
  public static readonly MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_RETRIEVE: string =
    environment.servicePath.FORMS_DATA_PERSISTENCE_SERVICE_PATH +
    's/form/data/rdbms/efeds.';
  public static readonly MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_UPDATE: string =
    environment.servicePath.FORMS_DATA_PERSISTENCE_SERVICE_PATH +
    's/form/data/rdbms/efeds/';
  public static readonly MAX_PAAS_FORM_DATA_RDBMS_PERSISTENCE_DELETE: string =
    environment.servicePath.FORMS_DATA_PERSISTENCE_SERVICE_PATH +
    's/form/data/rdbms/efeds.';
  public static readonly MAX_PAAS_DOCUMENTS_PERSISTENCE_URL: string =
    environment.servicePath.PERSISTENCE_SERVICE_PATH +
    'api/library/filestorage/data/';
  public static readonly MAX_PAAS_VALUES_PERSISTENCE_URL: string =
    environment.servicePath.VALUES_SERVICE_PATH +
    's/values/filestorage/efeds.domains/';
  public static readonly MAX_PAAS_VALUES_SEARCH_PERSISTENCE_URL: string =
    environment.servicePath.VALUES_SERVICE_PATH +
    's/values/rdbms/filter/efeds.efeds_domains.value';
  public static readonly MAX_PAAS_VALUES_SEARCH_DEFAULT_LIMIT: number = 50;

  public static readonly INTEGRITY_ACCESS_GROUP_ID = 'AGY-OGE-INTEGRITY.USERS';
  public static readonly MAX_PAAS_IDENTITY_MANAGER_USER_SEARCH_URL =
    environment.servicePath.GROUPS_SERVICE_PATH + 's/idm/users.json';
  public static readonly MAX_PAAS_IDENTITY_MANAGER_INTEGRITY_MEMBERSHIP_UPDATE_URL =
    environment.servicePath.GROUPS_SERVICE_PATH +
    's/idm/groups/' +
    ConfigService.INTEGRITY_ACCESS_GROUP_ID +
    '/members.json';
  public static readonly MAX_PAAS_IDENTITY_MANAGER_USER_PREFLIGHT_URL =
    environment.servicePath.GROUPS_SERVICE_PATH + 's/idm/agencies/email/';
  public static readonly MAX_PAAS_GRID_DATA_RDBMS_PERSISTENCE_URL: string =
    environment.servicePath.GRID_DATA_PERSISTENCE_SERVICE_PATH +
    's/grid/data/rdbms/';

  public static readonly MAX_PAAS_GRID_DATA_PERSISTENCE_URL: string =
    environment.servicePath.GRID_DATA_PERSISTENCE_SERVICE_PATH +
    's/grid/data/elasticsearch/';
  public static readonly MAX_PAAS_GRID_DATA_SEARCH_PERSISTENCE_URL: string =
    environment.servicePath.GRID_DATA_PERSISTENCE_SERVICE_PATH +
    's/grid/data/elasticsearch/filter/';
  public static readonly MAX_PAAS_GRID_DATA_DELETE_INDEX_URL: string =
    environment.servicePath.GRID_DATA_PERSISTENCE_SERVICE_PATH +
    's/grid/data/elasticsearch/index/';

  public static readonly MAX_PAAS_APP_DATA_RDBMS_PERSISTENCE_URL: string =
    environment.servicePath.APP_DATA_SERVICE_PATH + 's/appdata/rdbms/';

  public static readonly SESSION_USERS_JSON: string =
    environment.servicePath.SESSION_SERVICE_PATH + 's/session/cas/users.json';
  public static readonly SESSION_PROFILE_JSON: string =
    environment.servicePath.SESSION_SERVICE_PATH +
    's/session/cas/users/profile.json';

  // Start IntegrityServiceNode queries (formerly WSO2)
  public static readonly INTEGRITY_SERVICE_NODE_BASE: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH + 'query/';
  public static readonly INTEGRITY_SERVICE_GET_USER_ROLES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_user_roles';
  public static readonly INTEGRITY_SERVICE_GET_USER_TOGGLES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_user_toggles';
  public static readonly INTEGRITY_SERVICE_GET_CANONICAL_ID: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_canonical_user_id';
  public static readonly INTEGRITY_SERVICE_GET_MY_TASKS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_my_tasks_filings';
  public static readonly INTEGRITY_SERVICE_GET_FILING_WORKFLOW: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_workflow_nominee';
  public static readonly INTEGRITY_SERVICE_GET_MINIMAL_CONTACT_INFO: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_minimal_contact_info';
  public static readonly INTEGRITY_SERVICE_GET_CONTACT_INFO_BY_TIMESTAMP: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_contact_info_by_timestamp';
  public static readonly INTEGRITY_SERVICE_GET_FILER_DESIGNEES_CONTACT_INFO: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_filer_designees_contact_info';
  public static readonly INTEGRITY_SERVICE_GET_AGENCY_SUPPORT_HELP_FOR_USER: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_agency_support_help_for_user';
  public static readonly INTEGRITY_SERVICE_GET_DOCUMENTS_FOR_AGENCY: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_documents_for_agency';
  public static readonly INTEGRITY_SERVICE_GET_INTEGRITY_USER_RESOURCES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_integrity_user_resources';  
  public static readonly INTEGRITY_SERVICE_GET_LANDING_PAGE_CONFIGS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_landing_page_configs';
  public static readonly INTEGRITY_SERVICE_GET_FILER_GROUPS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + '/get_filer_groups';
  public static readonly INTEGRITY_SERVICE_GET_RELATED_GROUPS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_related_groups';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_NAME: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_group_name';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_NAMES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_group_names';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_DATA: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_group_data';
  public static readonly INTEGRITY_SERVICE_GET_PARENT_AGENCY_NAME: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_parent_agency_name';
  public static readonly INTEGRITY_SERVICE_GET_NON_WORKFLOW_USER_ROLES_FOR_FILING: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_non_workflow_user_roles_for_filing';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_PARENTS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_group_parents';
  public static readonly INTEGRITY_SERVICE_GET_USER_ROLES_FOR_FILING: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_user_roles_for_filing';
  public static readonly INTEGRITY_SERVICE_GET_FILING_WORKFLOW_STEP_PULL_FORWARD_RULES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_pull_forward_rules';
  public static readonly INTEGRITY_SERVICE_GET_FILING_WORKFLOW_STEP_PULL_FORWARD_RULES_BY_END_STEP: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_pull_forward_rules_by_end_step';
  public static readonly INTEGRITY_SERVICE_GET_PAS_GROUPS_TO_SHARE_NOMINEE_REPORT_WITH: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_pas_groups_to_share_nominee_report_with';
  public static readonly INTEGRITY_SERVICE_GET_GRID_COMMENTS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'grid_comments';
  public static readonly INTEGRITY_SERVICE_GET_GRID_POSITIONS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'grid_positions_all';
  public static readonly INTEGRITY_SERVICE_GET_FILING_DOCUMENTS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_documents_for_filing';
  public static readonly INTEGRITY_SERVICE_GET_AGENCY_CONFIG_SETTING: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_agency_config_setting';
  public static readonly INTEGRITY_SERVICE_IS_CONFIG_SETTING_TRUE_IN_ANY_GROUP: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'is_config_setting_true_in_any_group';  
  public static readonly INTEGRITY_SERVICE_CAN_USER_EDIT_FILING: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'can_user_edit_filing';
  public static readonly INTEGRITY_SERVICE_GET_LOGIN_SECURITY_LINK: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_login_security_link';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_AND_PARENT_AGENCY: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_group_and_parent_agency';
  public static readonly INTEGRITY_SERVICE_GET_USER_BASICS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_user_basics';
  public static readonly INTEGRITY_SERVICE_GET_FILING_NOTIFICATIONS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_notifications';
  public static readonly INTEGRITY_SERVICE_GET_FILING_MEMO_HASH: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_memo_hash';
  public static readonly INTEGRITY_SERVICE_SEARCH_BY_FILING: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'search_by_filing';
  public static readonly INTEGRITY_SERVICE_GET_MANAGE_REVIEWER_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_reviewer_queue';
  public static readonly INTEGRITY_SERVICE_GET_PREPOPULATED_CHANGES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_prepopulated_changes';
  public static readonly INTEGRITY_SERVICE_GET_DISTINCT_VALUES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_values_';
  public static readonly INTEGRITY_SERVICE_GET_REVIEWER_FILING_VALUES_AGENCY_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_reviewer_filing_values_agency_list';
  public static readonly INTEGRITY_SERVICE_GET_REVIEWER_FILING_VALUES_GROUP_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_reviewer_filing_values_group_list';
  public static readonly INTEGRITY_SERVICE_GET_FILING_CURRENT_ROLE_PENDING_ACTION_NOTIFICATION: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_filing_current_role_pending_action_notification';
  public static readonly INTEGRITY_SERVICE_GET_MANAGE_REVIEWER_ALTERNATES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_manage_reviewer_alternates';
  public static readonly INTEGRITY_SERVICE_DOES_USER_HAVE_OGE_OVERSIGHT_NOMINEE_ROLE: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'does_user_have_oge_oversight_nominee_role';
  public static readonly INTEGRITY_SERVICE_GET_CONTACT_INFO_HISTORY: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_contact_info_history';
  public static readonly INTEGRITY_SERVICE_GET_PREPOPULATION_SOURCE_REPORT_INFO: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_prepopulation_source_report_info';
  public static readonly INTEGRITY_SERVICE_GET_COMPARE_DATA: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_compare_data';
  public static readonly INTEGRITY_SERVICE_GET_COMPARE_FLAGS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_compare_flags';
  public static readonly INTEGRITY_SERVICE_CAN_USER_DOWNLOAD_FILING: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'can_user_download_filing';
  public static readonly INTEGRITY_SERVICE_PURGE_FILING: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'purge_filing';
  public static readonly INTEGRITY_SERVICE_DELETE_FILING: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'delete_filing';
  public static readonly INTEGRITY_SERVICE_GET_RECORDS_MANAGEMENT_BULK_ACTION_JOBS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_records_management_bulk_action_jobs';
  public static readonly INTEGRITY_SERVICE_GET_BULK_EXPORT_REPORTS_JOBS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_bulk_export_reports_jobs';
  public static readonly INTEGRITY_SERVICE_GET_DESCENDANT_GROUPS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_descendant_groups';
  public static readonly INTEGRITY_SERVICE_GET_USER_GROUPS_AND_AGENCIES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_user_groups_and_agencies';
  public static readonly INTEGRITY_SERVICE_GET_MGMT_REPORT_AGENCY_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_mgmt_report_agency_list';
  public static readonly INTEGRITY_SERVICE_GET_MGMT_REPORT_GROUP_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_mgmt_report_group_list';
  public static readonly INTEGRITY_SERVICE_GET_FIND_USER_AGENCY_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_find_user_agency_list';
  public static readonly INTEGRITY_SERVICE_GET_FIND_USER_GROUP_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_find_user_group_list';  
  public static readonly INTEGRITY_SERVICE_GET_ALL_ROLES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_all_roles';
  public static readonly INTEGRITY_SERVICE_GET_ALL_FILING_TYPES: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_all_filing_types';
  public static readonly INTEGRITY_SERVICE_GET_GRID_ROW: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'grid_';
  public static readonly INTEGRITY_SERVICE_GET_BULK_ASSIGN_REPORTS_JOBS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_bulk_assign_reports_jobs';
  public static readonly INTEGRITY_SERVICE_GET_FILERS_IN_AGENCY: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filers_in_agency';
  public static readonly INTEGRITY_SERVICE_GET_DEFAULT_BULK_REPORT_ASSIGNMENTS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_default_bulk_report_assignments';
  public static readonly INTEGRITY_SERVICE_GET_FILER_EXISTING_ASSIGNMENTS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_filer_existing_assignments';
  public static readonly INTEGRITY_SERVICE_LOOKUP_FILING_BY_AGENCY_USER_YEAR_ITEM_TYPE: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'lookup_report_by_agency_user_year_item_type';
  public static readonly INTEGRITY_SERVICE_MERGE_USER_ACCOUNTS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'merge_user_accounts';
  public static readonly INTEGRITY_SERVICE_REVERSE_MERGE_USER_ACCOUNTS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'reverse_merge_user_accounts';
  public static readonly INTEGRITY_SERVICE_SET_ACCOUNT_STATUS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'set_account_status';
  public static readonly INTEGRITY_SERVICE_ADD_NOMINEE_TO_TARGET_AGENCY: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'add_nominee_to_target_agency';
  public static readonly INTEGRITY_SERVICE_GET_INBOUND_NOMINEE_GROUPS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_inbound_nominee_groups';
  public static readonly INTEGRITY_SERVICE_GET_INBOUND_NOMINEE_GROUPS_FOR_USER: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_inbound_nominee_groups_for_user';
  public static readonly INTEGRITY_SERVICE_GET_NOMINEE_RELEASE_AGENCY_DETAILS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_nominee_release_agency_details';
  public static readonly INTEGRITY_SERVICE_GET_BULK_PROVISION_USERS_JOBS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_bulk_provision_users_jobs';
  public static readonly INTEGRITY_SERVICE_GET_ASSIGN_REPORTS_DATA: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_assign_reports_data';
  public static readonly INTEGRITY_SERVICE_GET_MANAGE_AGENCIES_AND_GROUPS_AGENCY_LIST =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_manage_agencies_and_groups_agency_list';
  public static readonly INTEGRITY_SERVICE_GET_MANAGE_AGENCIES_AND_GROUPS_GROUP_LIST =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_manage_agencies_and_groups_group_list';
  public static readonly INTEGRITY_SERVICE_GET_FILING_TYPES =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_types';
  public static readonly INTEGRITY_SERVICE_GET_WORKFLOW_TYPES =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_workflow_types';
  public static readonly INTEGRITY_SERVICE_FIND_CORRESPONDING_SPECIAL_AGENCY_AND_GROUP =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'find_corresponding_special_agency_and_group';
  public static readonly INTEGRITY_SERVICE_GET_ASSIGN_REPORTS_FILERS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_assign_reports_filers';
  public static readonly INTEGRITY_SERVICE_GET_PERMISSION_CHANGE_LOG_FOR_GROUP =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_permission_change_log_for_group';
  public static readonly INTEGRITY_SERVICE_SET_FLASH_MESSAGE =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'set_flash_message';
  public static readonly INTEGRITY_SERVICE_GET_FLASH_MESSAGE =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_flash_message';
  public static readonly INTEGRITY_SERVICE_GET_AUTOCOMPLETE_FILING_REVIEWERS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'autocomplete_filing_reviewers';
  public static readonly INTEGRITY_SERVICE_FACETED_SEARCH_FOR_USERS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'faceted_search_for_users';
  public static readonly INTEGRITY_SERVICE_FACETED_SEARCH_FOR_NON_NOMINEE_USERS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'faceted_search_for_non_nominee_users';
  public static readonly INTEGRITY_SERVICE_GET_ALL_INACTIVE_USER_IDS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_all_inactive_user_ids';
  public static readonly INTEGRITY_SERVICE_GET_USER_ROLES_FOR_OTHER_USER =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_user_roles_for_other_user';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_AND_ANCESTOR_USER_NAMES =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_group_and_ancestor_user_names';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_AND_ANCESTORS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_group_and_ancestors';
  public static readonly INTEGRITY_SERVICE_GET_AGENCY_GROUPS_USED_FILING_TYPES =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_agency_groups_used_filing_types';
  public static readonly INTEGRITY_SERVICE_GET_CHILDREN_COUNT =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_children_count';
  public static readonly INTEGRITY_SERVICE_GET_LAST_TEST_EMAIL_FOR_AGENCY =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_last_test_email_for_agency';
  public static readonly INTEGRITY_SERVICE_IS_AGENCY_NAME_UNIQUE =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'is_agency_name_unique';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_USED_FILING_TYPES =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_group_used_filing_types';
  public static readonly INTEGRITY_SERVICE_GET_WORKFLOWS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_workflows';
  public static readonly INTEGRITY_SERVICE_GET_GROUP_FILERS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_group_filers';
  public static readonly INTEGRITY_SERVICE_COUNT_FILINGS_BY_ROUGH_STATUS_FOR_USERS_IN_GROUP =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'count_filings_by_rough_status_for_users_in_group';
  public static readonly INTEGRITY_SERVICE_GET_NON_TERMINAL_FILING_IDS_FOR_USER_AND_GROUP =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_non_terminal_filing_ids_for_user_and_group';
  public static readonly INTEGRITY_SERVICE_IS_GROUP_NAME_UNIQUE =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'is_group_name_unique';
  public static readonly INTEGRITY_SERVICE_GET_CHILDREN_GROUPS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_children_groups';
  public static readonly INTEGRITY_SERVICE_GET_REVIEWER_ALL_AGENCY_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_reviewer_all_agency_list';
  public static readonly INTEGRITY_SERVICE_GET_REVIEWER_ALL_GROUPS_LIST: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_reviewer_all_group_list';
  public static readonly INTEGRITY_SERVICE_GET_FILING_LAST_REVISION =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_last_revision';
  public static readonly INTEGRITY_SERVICE_GET_FILING_REVISION_DATES =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_revision_dates';
  public static readonly INTEGRITY_SERVICE_DELETE_NOT_STARTED_FILINGS_FOR_USERS_IN_GROUP =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'delete_not_started_filings_for_users_in_group';
  public static readonly INTEGRITY_SERVICE_GET_MERGED_ACCOUNT_USERNAMES =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_merged_account_usernames';
  public static readonly INTEGRITY_SERVICE_GET_MERGED_ACCOUNT_EMAILS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_merged_account_emails';
  public static readonly INTEGRITY_SERVICE_GET_MERGED_ACCOUNT_AGENCIES =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_merged_account_agencies';
  public static readonly INTEGRITY_SERVICE_GET_BULK_EXPORT_REPORTS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_bulk_export_reports';
  public static readonly INTEGRITY_SERVICE_GET_FILING_DETAILS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_details';
  public static readonly INTEGRITY_SERVICE_GET_INITIAL_APPOINTMENT_DATE =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_initial_appointment_date';  
  public static readonly INTEGRITY_SERVICE_GET_SECURITY_LEVEL_CHANGE_LOG =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_security_level_change_log';
  public static readonly INTEGRITY_SERVICE_GET_AUDIT_LOG_ROWS =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_audit_log_rows';
  public static readonly INTEGRITY_SERVICE_GET_FILING_EXTENSIONS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE + 'get_filing_extensions';
  public static readonly INTEGRITY_SERVICE_GET_FILING_REVIEWER_EXTENSION_REQUEST_DECISION_NOTIFICATION: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_filing_reviewer_extension_request_decision_notification';
  public static readonly INTEGRITY_SERVICE_GET_EXTENSION_REVIEWERS: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_filing_extension_reviewer_roles';
  public static readonly INTEGRITY_SERVICE_GET_PENDING_EXTENSION_REQUEST_COUNT_FOR_AGENCY: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_pending_extension_request_count_for_agency';
  public static readonly INTEGRITY_SERVICE_HAS_NOMINEE_FILING_CHANGED_AFTER_PRECLEARANCE: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'has_nominee_filing_changed_after_preclearance';

  public static readonly INTEGRITY_SERVICE_GET_BULK_ASSIGN_REPORTS_JOB: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'get_bulk_assign_reports_job';
  public static readonly INTEGRITY_SERVICE_ARE_TRANSACTION_DATES_VALID: string =
    ConfigService.INTEGRITY_SERVICE_NODE_BASE +
    'are_transactions_dates_valid';


  // End IntegrityServiceNode queries (formerly WSO2)

  // Start IntegrityServiceNode path calls
  public static readonly INTEGRITY_SERVICE_CREATE_FILING: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH + 'filing';
  public static readonly INTEGRITY_SERVICE_ADD_COMMENT: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingid}/comments';
  public static readonly INTEGRITY_SERVICE_FILING_FILING_TYPE: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/filingtype';
  public static readonly INTEGRITY_SERVICE_GET_FILING: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH + 'filing/{filingid}';
  public static readonly INTEGRITY_SERVICE_FILING_SIGN: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/sign';
  public static readonly INTEGRITY_SERVICE_SAVE_WIZARD_FORM: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/wizard/form/{formId}/surrogatekey/{surrogateKey}';
  public static readonly INTEGRITY_SERVICE_SAVE_INDICATIVE_DATA_FRAGMENT: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/fragment/wizard/form/{formId}/surrogatekey/{surrogateKey}/updateFiling/{updatefiling}';
  public static readonly INTEGRITY_SERVICE_SAVE_FILING_FRAGMENT: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingid}/fragment';
  public static readonly INTEGRITY_SERVICE_RESET_FILING: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingid}/reset';
  public static readonly INTEGRITY_SERVICE_FILING_TRANSACTIONS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/transactions';
  public static readonly INTEGRITY_SERVICE_FILING_IMPORT_TRANSACTIONS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/import_transactions';
  public static readonly INTEGRITY_SERVICE_FILING_IMPORT_ASSETS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/import_assets';
  public static readonly INTEGRITY_SERVICE_FILING_IMPORT_SPOUSE_ASSETS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/import_spouse_assets';
  public static readonly INTEGRITY_SERVICE_FILING_IMPORT_OTHER_ASSETS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/import_other_assets';
  public static readonly INTEGRITY_SERVICE_COPY_JOB_DESCRIPTION: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/jobdescription';
  public static readonly INTEGRITY_SERVICE_FILING_SAVE_WIZARD_CONTINUE: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/wizardcontinue';

  public static readonly INTEGRITY_SERVICE_BULK_EXPORT_REPORTS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'admin/bulkexport/pdf';

  public static readonly INTEGRITY_SERVICE_USER_PREFERENCES: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH + 'user/me/preferences';
  public static readonly INTEGRITY_SERVICE_FILING_MEMO: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/memo/{source}';

  public static readonly INTEGRITY_SERVICE_ANNUAL_DATA_EXTRACT: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'admin/managementreports/annualDataExtract';
  public static readonly INTEGRITY_SERVICE_NOTIFY_FILERS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'group/{groupId}/filers/notify';

  public static readonly INTEGRITY_SERVICE_RECORDS_MANAGEMENT: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'reviewer/recordsmanagement';

  public static readonly INTEGRITY_SERVICE_BULK_ACTION_RECORDS_MGMT_REPORTS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'reviewer/bulk_action_records_mgmt_reports';

  public static readonly LOG_ERROR: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH + 'log_error';

  public static readonly INTEGRITY_SERVICE_REVIEWER_SEARCH: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH + 'reviewer/search';

  public static readonly INTEGRITY_SERVICE_DELETE_GROUP: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH + 'group/{groupId}';

  public static readonly INTEGRITY_SERVICE_REVIEWER_QUEUE: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH + 'reviewer/queue';

  public static readonly INTEGRITY_SERVICE_NOTIFICATIONS_TEST_EMAIL =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'agency/{agencyId}/notifications/test';

  public static readonly INTEGRITY_SERVICE_IMPORT_278T_TRANSACTIONS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/transactions/{sourceFilingId}';
  public static readonly INTEGRITY_SERVICE_SAVE_EXTENSION_REQUEST: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/extensionrequest';
  public static readonly INTEGRITY_SERVICE_UPDATE_EXTENSION_REQUEST: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/extensionrequest/{{requestId}}';
  public static readonly INTEGRITY_SERVICE_SAVE_EXTENSION_GRANT: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/extensiongrant';
  public static readonly INTEGRITY_SERVICE_UPDATE_EXTENSION_GRANT: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/extensiongrant/{{extensionId}}';
  public static readonly INTEGRITY_SERVICE_WITHDRAW_EXTENSION_REQUEST: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/extensionrequest/{requestId}/withdraw';

  public static readonly INTEGRITY_SERVICE_MOVE_ASSETS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingid}/moveassets';

  public static readonly INTEGRITY_SERVICE_PREPOPULATE: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'filing/{filingId}/prepopulate';

  public static readonly INTEGRITY_SERVICE_GET_REVIEWER_RECORDS_MANAGEMENT_LIST: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'reviewer/recordsmanagement';  

  public static readonly INTEGRITY_SERVICE_REVIEWER_RECORDS_SEARCH_LIST: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'reviewer/records_search';

  public static readonly INTEGRITY_SERVICE_GET_FIND_USER_UNRESTRICTED =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'admin/find_users';
  
  public static readonly INTEGRITY_SERVICE_GET_REVIEWER_QUEUE: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'reviewer/my_queue';

  public static readonly INTEGRITY_SERVICE_BULK_ASSIGN_REPORTS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'admin/bulkassign';


  public static readonly INTEGRITY_SERVICE_MANAGEMENT_REPORTS: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'admin/managementreports';
  public static readonly INTEGRITY_SERVICE_MANAGEMENT_REPORTS_CONFIG: string =
    environment.servicePath.INTEGRITY_SERVICE_NODE_PATH +
    'admin/managementreports/config';

  // End IntegrityServiceNode path calls

  // Start IntegrityService (Java) calls
  public static readonly INTEGRITY_SERVICE_FILING: string =
    environment.servicePath.INTEGRITY_SERVICE_PATH + 'filing';

  public static readonly INTEGRITY_SERVICE_FILING_DOWNLOAD: string =
    environment.servicePath.INTEGRITY_SERVICE_PATH +
    'filing/{filingId}/download/pdf';

  // End IntegrityService (Java) calls

  constructor() {}
}
