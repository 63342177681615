import { Injectable, OnDestroy } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { ConfigService } from '../services/config.service';
import { takeUntil, tap } from 'rxjs/operators';
import { QueryService } from '../services/query.service';
import { Observable, Subject } from 'rxjs';
import { SessionService } from '../services/session.service';
import { DocumentDownload } from '../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class MenuItemsService implements OnDestroy {
  protected destroyed$: Subject<boolean> = new Subject();

  userResourcesLink = ConfigDataService.LANDING_PAGE_USER_RESOURCES;
  
  constructor(
    private queryService: QueryService,
    private sessionService: SessionService,
  ) { }

  init(): void {
  }
  
  download(document: DocumentDownload) {
    if (!document) {
      return;
    }
    
    this.sessionService.setUploadAuthCookie();
    window.open(
      ConfigService.MAX_PAAS_DOCUMENTS_PERSISTENCE_URL +
      document.scope +
      '/' +
      document.name
    );
    setTimeout(() => this.sessionService.deleteUploadAuthCookie(), 500);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
