import { Role, RoleKey, RoleType } from './role.model';
import { Filing } from './filing.model';
import { ReportStatus } from './status.model';
import _ from 'lodash';

/**
 * The minimum details needed to download a document file
 */
export interface DocumentDownload {
  name: string;
  scope: string;
  category?: string;
}

export class Document {
  added: number | string; // timestamp in milliseconds (This can be a string because we pass {{java.lang.System.currentTimeMillis()}} back to server)
  documentId: string;
  filingId: string;
  name: string;
  note: string;
  retentionPeriod: string; // @see ValueService.getDocumentTypes()
  scope: string; // e.g. 'A925573___D197E8A8-503B-41FC-AD18-F2662685D671'
  timestamp: number; // server timestamp
  sessionUserId: string; // userId format, e.g. A925573
  size: number;
  type: string; // @see ValueService.getRetentionPeriodOptions()
  uploader: string; // userId format, e.g. A925573
  category?: string;
  agencyId?: string;
  groupSurrogateKey?: string;
  filename?: string;
  // For job description
  prepopulated?: string;
  filerReviewed?: boolean;
  // For agency document ordering OGE-9060
  sortOrder?: number;

  static createFromJson(item: any): Document {
    if ('data' in item) {
      item = item.data;
    }
    const doc: Document = new Document();
    doc.added = item.added;
    doc.documentId = item.documentId;
    doc.filingId = item.filingId;
    doc.name = item.name;
    doc.note = item.note;
    doc.retentionPeriod = item.retentionPeriod;
    doc.scope = item.scope;
    doc.timestamp = item.server_timestamp;
    doc.sessionUserId = item.session_user_id;
    doc.size = item.size;
    doc.type = item.type;
    doc.uploader = item.uploader;
    doc.category = item.category;
    doc.agencyId = item.agencyId;
    doc.groupSurrogateKey = item.groupSurrogateKey;
    doc.filename = item.filename;
    doc.prepopulated = item.prepopulated;
    doc.filerReviewed = item.filerReviewed;
    doc.sortOrder = !!item.sortOrder ? item.sortOrder : 0;
    return doc;
  }

  static createArrayFromJson(data: any[]): Document[] {
    const documents: Document[] = [];
    if (!!data && data.length > 0) {
      data.forEach((item) => {
        const doc: Document = this.createFromJson(item);
        documents.push(doc);
      });
    }
    return documents;
  }

  static canEditDocuments(roles: Role[], filing: Filing): boolean {
    return roles.reduce<boolean>((show, role) => {
      return show || this.canRoleEditDocuments(role, filing);
    }, false);
  }

  static canEditMetaOrDeleteDocuments(roles: Role[]): boolean {
    return roles.reduce<boolean>((show, role) => {
      return show || this.canRoleEditMetaOrDeleteDocuments(role);
    }, false);
  }

  static canRoleEditDocuments(role: Role, filing: Filing): boolean {
    let status: string;

    switch (role.surrogateKey) {
      case RoleKey.AgencyAdministrator:
      case RoleKey.PPOLead:
      case RoleKey.PPOReviewer:
      case RoleKey.PTTLead:
      case RoleKey.PTTReviewer:
      case RoleKey.WHCOLead:
      case RoleKey.WHCOReviewer:
        return false;
      case RoleKey.NomineeReviewer:
        // EFEDS-5565 Nominee Reviewer should not be able to edit reports beyond the draft stage.
        status = filing.workflow.status.status;
        if (
          filing.item == 'Nominee Report' &&
          (status == ReportStatus.DraftUnderReview ||
            status == ReportStatus.DraftPreCleared)
        ) {
          return true;
        }
        return false;
      default:
        status = filing.workflow.status.status;

        // EFEDS-4650 User PPO and a target agency role can't edit documents until after it's been released.
        if (
          filing.item == 'Nominee Report' &&
          (status == ReportStatus.NotStarted ||
            status == ReportStatus.Draft ||
            status == ReportStatus.DraftPendingRelease) &&
          role.group.surrogateKey == filing.group.surrogateKey
        ) {
          return false;
        }

        return true;
    }
  }

  static canRoleEditMetaOrDeleteDocuments(role: Role): boolean {
    switch (role.surrogateKey) {
      case RoleKey.Filer:
        return false;
      default:
        return true;
    }
  }

  toJson(): any {
    let json: any = {};
    for (const prop of Object.keys(this)) {
      switch (prop) {
        default: {
          json[prop] = (this as any)[prop];
        }
      }
    }
    json = _.omitBy(json, _.isUndefined);
    return json;
  }
  
  get prepopulatedWarning(): boolean {
    return !!this.prepopulated && !this.filerReviewed;
  }
}
